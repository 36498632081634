.mainMaintenance {
  height: 100vh;
  margin: 0;
}
.bgimg {
  background-image: url('/maintenance.png');
  // background-image: url('/website-under-construc.gif');
  height: 100%;
  background-position: top center;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  color: white;
  font-family: 'Courier New', Courier, monospace;
  font-size: 25px;
}

.topleft {
  img {
    width: 171px;
  }
  position: absolute;
  top: 15px;
  left: 16px;
}

.bottomleft {
  position: absolute;
  bottom: 15px;
  left: 16px;
  p {
    max-width: 100%;
    font-size: 18px;
    letter-spacing: 0.05rem;
    line-height: 30px;
    width: 530px;
    color: #a8a8a8;
  }
}

.middle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

/* mobile  */

@media only screen and (max-width: 480px) and (min-width: 320px) {
  .bgimg {
    background-position: center;
  }
  .bottomleft {
    position: absolute;
    bottom: 15px;
    left: 16px;
    p {
      width: 100%;
    }
  }
}
