.wrapLogin {
    background-image: url('/login-bg.png');
    color: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    overflow: hidden;
    position: relative;
    background-position: center;
    background-size: cover;
    z-index: 0;
    .loginSectionWrap {
        margin-bottom: 50px;
        max-width: 450px;
        margin: 0 auto;
        .loginSectionWidth {
            padding: 48px 68px;
            background-color: rgb(0 0 0 / 77%);
            border-radius: 4px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            margin: 0;
            width: 100%;
            .title {
                font-size: 32px;
                font-weight: 600;
                letter-spacing: 0.03rem;
                padding-bottom: 20px;
            }
            i {
                position: absolute;
                margin-top: -3%;
                margin-left: 24%;
                font-size: 18px;
                font-weight: 100 !important;
                color: #ffffffdb;
                cursor: pointer;
            }
        }
    }
  }
.wrapLogin::before{
    background-color: rgb(0 0 0 / 57%);
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    z-index: -1;
    width: 100%;
}

/* mobile  */

@media only screen and (max-width: 480px) and (min-width: 320px) {

.bigImg {
    text-align: center;
    padding: 20px;
}
.wrapLogin {
    .loginSectionWrap {
        .loginSectionWidth {
            padding: 48px 45px;
            i {
                margin-top: -10%;
                margin-left: 75%;
            }
        }
    }
}
}

// @media only screen and (max-width: 1536px) and (min-width: 864px) {
//     .wrapLogin {
//         .loginSectionWrap {
//             .loginSectionWidth {
//                 padding: 48px 45px;
//                 i {
//                     margin-top: -10%;
//                     margin-left: 75%;
//                 }
//             }
//         }
//     }
// }